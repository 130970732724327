import React from 'react';
import PropTypes from 'prop-types';
import { GitHubButton } from './Buttons';
import { connect } from 'react-redux';

const HomeHero = ({ mainHero, title, subtitle, store }) => {
  return (
    <div className="hero cover">
      <style
        dangerouslySetInnerHTML={{
          __html: `
            .hero {
              background-image: url(${mainHero[0].url});
            }
            @media screen and (max-width:1200px) {
              .hero {
                background-image: url(${mainHero[1].url});
              }
            @media screen and (max-width: 767px) {
              .hero {
                background-image: url(${mainHero[2].url});
              }
            }
          `,
        }}
      />
      <div className="main-container">
        <div className="hero__content">
          <div
            className="hero__title"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <div
            className="hero__text"
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
          {!store.github.data.token && <GitHubButton />}
        </div>
      </div>
    </div>
  );
};

HomeHero.propTypes = {
  store: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  mainHero: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
    }),
  ),
};

const mapStateToProps = state => {
  return {
    store: state.authentication,
  };
};

export default connect(mapStateToProps)(HomeHero);
