import authFetch from '../utils/authenticatedFetch';

import { AUTH_URL } from '../env';

export function getProfile() {
  return authFetch(`${AUTH_URL}/v1/users/byID/me?scopes=${'core,public'}`).then(
    res => res.data,
  );
}

export function ArduinoLogin() {
  window.auth.login();
}

export function ArduinoLogOut() {
  window.auth.logout();
}
