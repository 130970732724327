import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { authenticationActions } from '../../store/authentication';
import { withRouter } from 'react-router';

const Layout = ({ children, onLogin, loggedIn, location }) => {
  useEffect(() => {
    if (!loggedIn) {
      onLogin(window.location.href);
    }
  }, []);
  return (
    <div className={`app ${location.pathname !== '/' ? 'default-page' : ''}`}>
      <main className="main-layout">{children}</main>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    loggedIn: state.authentication.login.loggedIn,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLogin: redirect => dispatch(authenticationActions.login(redirect)),
  };
};

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.func,
  ]),
  history: PropTypes.object,
  data: PropTypes.object,
  onLogin: PropTypes.func,
  loggedIn: PropTypes.bool,
  location: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Layout));
