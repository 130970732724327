import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import './Home.scss';

import HomeHero from './HomeHero';
import HomeOffer from './HomeOffer';
import GetStarted from './GetStarted';
import { connect } from 'react-redux';
import { GitHubButton } from './Buttons';
import GithubMessages from './GithubMessages';
import CodeModal from './CodeModal';

const Home = ({ data: { home }, store }) => {
  if (!home) {
    return <Fragment />;
  }
  const {
    title,
    subtitle,
    mainHero,
    offerTitle,
    offerImage,
    offerDescription,
    content,
    getStartedLink,
    getStartedText,
    getStartedTitle,
    getStartedHero,
    modalText,
  } = home;

  const [state, setState] = useState({});

  return (
    <div className="home">
      <HomeHero title={title} subtitle={subtitle} mainHero={mainHero} />
      <div className="main-container">
        <div className="offers__content">
          <div className="offers__image">
            <img src={offerImage.url} alt={offerTitle} />
          </div>
          <div className="offers__title">{offerTitle}</div>
          <div
            className="offers__description"
            dangerouslySetInnerHTML={{ __html: offerDescription }}></div>
          <div className="center">
            {!store.github.data.token && <GitHubButton />}
          </div>
        </div>
        <div className="offers__list">
          {!!content.length &&
            content.map(({ offer }) => {
              return (
                <HomeOffer {...offer} key={offer.id} setState={setState} />
              );
            })}
        </div>
      </div>
      <GetStarted
        {...{
          getStartedLink,
          getStartedText,
          getStartedTitle,
          getStartedHero,
        }}
      />
      <GithubMessages />
      <CodeModal text={modalText} state={state} setState={setState} />
    </div>
  );
};

Home.propTypes = {
  store: PropTypes.object,
  data: PropTypes.shape({
    home: PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string,
      mainHero: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string,
        }),
      ),
      offerTitle: PropTypes.string,
      offerImage: PropTypes.shape({
        url: PropTypes.string,
      }),
      offerDescription: PropTypes.string,
      content: PropTypes.array,
      getStartedLink: PropTypes.string,
      getStartedTitle: PropTypes.string,
      getStartedText: PropTypes.string,
      getStartedHero: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string,
        }),
      ),
      modalText: PropTypes.string,
    }),
  }),
};

const mapStateToProps = state => {
  return {
    store: state.authentication,
  };
};

export default connect(mapStateToProps)(Home);
