import React from 'react';
import PropTypes from 'prop-types';
import Link from '../ui-common/Link';

const GetStarted = ({
  getStartedLink,
  getStartedText,
  getStartedTitle,
  getStartedHero,
}) => {
  if (!getStartedHero.length) {
    return null;
  }
  return (
    <div className="get-started cover">
      <style
        dangerouslySetInnerHTML={{
          __html: `
          .get-started {
            background-image: url(${getStartedHero[0].url});
          }
          @media screen and (max-width: 1200px) {
            .get-started {
              background-image: url(${getStartedHero[1].url});
            }
          }
          @media screen and (max-width: 767px) {
            .get-started {
              background-image: url(${getStartedHero[2].url});
            }
          }
      `,
        }}
      />
      <div className="main-container">
        <div className="get-started__content">
          <div
            className="get-started__title"
            dangerouslySetInnerHTML={{ __html: getStartedTitle }}
          />

          <div
            className="get-started__text"
            dangerouslySetInnerHTML={{
              __html: getStartedText,
            }}
          />

          {getStartedLink && (
            <Link
              className="get-started__link"
              to={getStartedLink}
              external={true}>
              GETTING STARTED
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

GetStarted.propTypes = {
  getStartedLink: PropTypes.string,
  getStartedTitle: PropTypes.string,
  getStartedText: PropTypes.string,
  getStartedHero: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
    }),
  ),
};

export default GetStarted;
