/**
 * Monkey-patch the fetch function to send the authentication header
 */

export default function authenticatedFetch(input, init) {
  let patchedInit = init;

  if (!init || typeof init !== 'object') {
    patchedInit = {};
  }

  return window.oauth.token().then(authToken => {
    const patchedHeaders = Object.assign(patchedInit.headers || {}, {
      Authorization: `Bearer ${authToken.token}`,
    });

    patchedInit = Object.assign(patchedInit, {
      headers: patchedHeaders,
    });

    return fetch(input, patchedInit);
  });
}
