import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Link from '../ui-common/Link';
import Icon from '../ui-common/icon';

import { redeemCodeRequest } from '../../apis/github';
import { connect } from 'react-redux';

import {
  updateBenefits,
  setGitHubMessage,
} from '../../store/authentication/actions';
import { GITHUB_MESSAGES } from '../../utils/constants';

const sanitizeMessage = (message, name, offerName) => {
  if (message) {
    const productName = offerName.replace(/(<([^>]+)>)/gi, '');
    return message.replace(name, `<strong>${productName}</strong>`);
  }
};
const HomeOffer = props => {
  const {
    image,
    offerName,
    description,
    storeUrl,
    redeemLinkText,
    storeLinkText,
    setState,
    name,
    store,
    isWide,
  } = props;

  const isGitHubToken = store.github.data.token;
  const isProfile = store.login.isProfile;
  const isAuthenticate = isGitHubToken && isProfile;
  const benefits = store.github.benefits;

  const [isLoading, setLoading] = useState(false);

  const redeemCode = async event => {
    event.preventDefault();

    if (!isGitHubToken) {
      return props.setGitHubMessage(GITHUB_MESSAGES['GITHUB_TOKEN']);
    }

    if (!isProfile) {
      return props.setGitHubMessage(
        GITHUB_MESSAGES['ARDUINO_AUTH_WITH_GITHUB_TOKEN'],
      );
    }

    setLoading(true);

    // get existing coupon
    const coupon = benefits[name] && benefits[name].coupon;

    if (coupon) {
      setLoading(false);
      return setState({ isOpen: true, link: storeUrl, code: coupon });
    }

    // if coupon is not existing
    if (isGitHubToken) {
      const data = await redeemCodeRequest(name);

      if (data.message) {
        props.setGitHubMessage({
          message: sanitizeMessage(data.message, name, offerName),
          button: 'ARDUINO_BUTTON',
        });
      }

      if (data['coupon_code']) {
        setState({ isOpen: true, link: storeUrl, code: data['coupon_code'] });
        props.updateBenefits({
          [name]: {
            coupon: data['coupon_code'],
          },
        });
      }
      setLoading(false);
    }
  };

  return (
    <div className={`offers__item ${isWide && 'offers__item--wide'}`}>
      <div className="offers__item-image">
        <img src={image.responsiveImage.src} />
      </div>
      <div className="offers__item-content">
        <div
          className="offers__item-title"
          dangerouslySetInnerHTML={{ __html: offerName }}
        />

        <div
          className="offers__item-text"
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <div className="offers__links">
          <div className="offers__item-link-out--wrapper">
            <Link className="offers__item-link-out" to={storeUrl}>
              {storeLinkText}
              <Icon name="link-out" className="offers__item-link-out-icon" />
            </Link>
          </div>
          <div
            className={`offers__item-link ${!isAuthenticate && 'disabled'}`}
            onClick={redeemCode}>
            {redeemLinkText}
            {isLoading && <div className="button-loader"></div>}
          </div>
        </div>
      </div>
    </div>
  );
};

HomeOffer.propTypes = {
  setGitHubMessage: PropTypes.func,
  updateBenefits: PropTypes.func,
  store: PropTypes.object,
  image: PropTypes.shape({
    responsiveImage: PropTypes.shape({
      src: PropTypes.string,
    }),
  }),
  tag: PropTypes.string,
  offerName: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  storeUrl: PropTypes.string,
  redeemUrl: PropTypes.string,
  redeemLinkText: PropTypes.string,
  storeLinkText: PropTypes.string,
  setState: PropTypes.func,
  isWide: PropTypes.bool,
};

const mapStateToProps = state => {
  return {
    store: state.authentication,
  };
};

export default connect(mapStateToProps, { setGitHubMessage, updateBenefits })(
  HomeOffer,
);
