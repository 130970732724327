import React from 'react';
import PropTypes from 'prop-types';
import Link from '../ui-common/Link';
import { CopyButton } from './Buttons';
import Modal from '../ui-common/modal/Modal';

function CodeModal({ text, state, setState }) {
  const { isOpen, code, link } = state;
  return (
    <Modal open={isOpen} onClose={() => setState({})}>
      <div className="modal__title">Here’s your discount code!</div>
      <div className="modal__row">
        <input
          type="text"
          className="modal__input"
          readOnly
          id="code-input"
          value={code}
        />
        <CopyButton />
      </div>
      <div className="modal__text" dangerouslySetInnerHTML={{ __html: text }} />
      {link && (
        <Link className="modal__link" to={link}>
          GO TO THE STORE
        </Link>
      )}
    </Modal>
  );
}

CodeModal.propTypes = {
  text: PropTypes.string,
  state: PropTypes.object,
  setState: PropTypes.func,
};

export default CodeModal;
