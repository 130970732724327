import React, { useState } from 'react';

import Link from '../ui-common/Link';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import { GITHUB_URL, BASE_URL } from '../../env';
import { connect } from 'react-redux';
import { clearGitHubMessage } from '../../store/authentication/actions';
import { ArduinoLogin } from '../../apis/profile';

const GitHubButtonComponent = ({ location }) => {
  return (
    <a
      className="default-button"
      href={`${GITHUB_URL}&redirect_uri=${
        window.location.origin
      }${BASE_URL}${location.pathname.replace('/', '')}`}>
      Sign in with github
    </a>
  );
};

GitHubButtonComponent.propTypes = {
  location: PropTypes.object,
};

export const GitHubButton = withRouter(GitHubButtonComponent);

export const AuthButton = () => {
  return (
    <span className="default-button" onClick={ArduinoLogin}>
      Sign in
    </span>
  );
};

export const LinkButtonComponent = ({ to, text, dispatch, close }) => {
  return (
    <Link
      className="default-button"
      to={to}
      onClick={() => close && dispatch(clearGitHubMessage())}>
      {text}
    </Link>
  );
};

LinkButtonComponent.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  dispatch: PropTypes.func,
  close: PropTypes.bool,
};

export const LinkButton = connect(null, null)(LinkButtonComponent);

export const DoneButtonComponent = ({ dispatch }) => {
  return (
    <div
      className="default-button"
      onClick={() => close && dispatch(clearGitHubMessage())}>
      DONE
    </div>
  );
};

DoneButtonComponent.propTypes = {
  dispatch: PropTypes.func,
};

export const DoneButton = connect(null, null)(DoneButtonComponent);

export const CopyButton = () => {
  const [state, setState] = useState({ text: 'copy', className: '' });
  return (
    <button
      className={`modal__button ${state.className}`}
      onClick={() => {
        const input = document.getElementById('code-input');
        input.select();
        input.setSelectionRange(0, 99999);
        document.execCommand('copy');
        setState({ text: 'copied !', className: 'modal__button--copied' });
      }}>
      {state.text}
    </button>
  );
};
