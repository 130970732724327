import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../ui-common/modal/Modal';
import Icon from '../ui-common/icon';

import { LinkButton, GitHubButton, AuthButton, DoneButton } from './Buttons';

import { setGitHubMessage } from '../../store/authentication/actions';
import { connect } from 'react-redux';

const BUTTONS = {
  AUTH_BUTTON: <AuthButton />,
  ARDUINO_BUTTON: (
    <LinkButton text="Visit the store" to="https://store.arduino.cc/" />
  ),
  GITHUB_BUTTON: <GitHubButton />,
  REDIRECT_TEACHER_BUTTON: (
    <LinkButton text="GO TO TEACHER PAGE" to="/teachers" close />
  ),
  REDIRECT_STUDENT_BUTTON: (
    <LinkButton text="GO TO STUDENT PAGE" to="/students" close />
  ),
  DONE: <DoneButton />,
};
const GithubMessages = ({ data, ...props }) => {
  const { message, lock, title, button, status } = data;
  const onClose = () => props.setGitHubMessage({ ...data, message: '' });
  return (
    <Modal open={!!message} lock={lock} onClose={onClose}>
      <Icon name={`status-${!!status}`} className="modal__icon" />
      <div className="modal__title modal__title--message">
        {title || 'Oh no!'}
      </div>
      <div
        className={`modal__text ${!button && 'modal__text--message'}`}
        dangerouslySetInnerHTML={{ __html: message }}
      />
      {BUTTONS[button]}
    </Modal>
  );
};

GithubMessages.propTypes = {
  setGitHubMessage: PropTypes.func,
  data: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    data: state.authentication.github.message,
  };
};

export default connect(mapStateToProps, { setGitHubMessage })(GithubMessages);
