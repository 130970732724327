/* eslint-disable */

/**
 * This file exposes the variables passed from Webpack and read from the current env file (/env/*.sh)
 */

export const APP_NAME = process.env.APP_NAME;
export const BASE_URL = process.env.BASE_URL;
export const APP_URL = process.env.APP_URL;
export const AUTH_URL = process.env.AUTH_URL;
export const CDN_URL = process.env.CDN_URL;
export const API_URL = process.env.API_URL;
export const NODE_ENV = process.env.NODE_ENV;

export const DATO_CMS_GQL_API = process.env.DATO_CMS_GQL_API;
export const DATO_CMS_API_TOKEN = process.env.DATO_CMS_API_TOKEN;

export const SUPPORT_API = process.env.SUPPORT_API;

export const HF_URL = process.env.HF_URL;
export const PROFILE_URL = process.env.PROFILE_URL;
export const DIGITAL_STORE_URL = process.env.DIGITAL_STORE_URL;
export const ARDUINO_URL = process.env.ARDUINO_URL;
export const REDIRECT_URL = process.env.REDIRECT_URL;

export const MESSAGES_URL = process.env.MESSAGES_URL;
export const EDUCATION_STORE_URL = process.env.EDUCATION_STORE_URL;

export const GITHUB_URL = process.env.GITHUB_URL;

export const NODE_ENV_DEV = NODE_ENV === 'development';
