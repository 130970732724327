import React from 'react';
import PropTypes from 'prop-types';

import './Modal.scss';

class Modal extends React.Component {
  render() {
    const className = `arduino-modal  ${this.props.open ? 'open' : ''} ${this
      .props.classes || 'primary'}`;

    return (
      <div className={className}>
        <div
          className="modal-backdrop"
          onClick={() => {
            if (!this.props.lock) {
              this.props.onClose();
            }
          }}
        />

        <div className="modal-body">
          {!this.props.lock && (
            <div className="modal-close" onClick={this.props.onClose}></div>
          )}
          {this.props.children}
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  classes: PropTypes.string,
  lock: PropTypes.bool,
};

export default Modal;
