import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';

const isExternalLink = url => {
  const host = window.location.hostname;

  const linkHost = (url => {
    if (/^https?:\/\//.test(url)) {
      const parser = document.createElement('a');
      parser.href = url;

      return parser.hostname;
    } else {
      return window.location.hostname;
    }
  })(url);

  return host !== linkHost;
};

const Link = ({ to, children, external, ...rest }) => {
  if (isExternalLink(to) || external) {
    return (
      <a href={to} target="_blank" rel="noopener noreferrer" {...rest}>
        {children}
      </a>
    );
  }
  return (
    <RouterLink to={to} {...rest}>
      {children}
    </RouterLink>
  );
};

Link.propTypes = {
  to: PropTypes.string,
  external: PropTypes.bool,
  rest: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Link;
