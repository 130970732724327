import gql from 'graphql-tag';

import Home from './Home';
import QueryWrapper from '../../utils/QueryWrapper';
import { connect } from 'react-redux';
import { setCookie } from '../../utils/cookies';

const QUERY = gql`
  query($locale: SiteLocale!, $slug: String!) {
    home: githubHome(locale: $locale, filter: { slug: { eq: $slug } }) {
      title(markdown: true)
      subtitle(markdown: true)
      mainHero {
        url
      }
      offerImage {
        url
      }
      offerTitle
      offerDescription(markdown: true)

      content {
        offer {
          id
          name
          offerName(markdown: true)
          redeemLinkText
          storeLinkText
          storeUrl
          description(markdown: true)
          image {
            responsiveImage(imgixParams: { w: "635" }) {
              src
            }
          }
          isWide
        }
      }
      getStartedLink
      getStartedText
      getStartedTitle(markdown: true)
      getStartedHero {
        url
      }
      modalText(markdown: true)
    }
  }
`;

const HomeContainer = props => {
  const slug = props.match.path.replace('/', '') || 'students';
  setCookie('model', slug);
  return QueryWrapper(QUERY, Home, props, {
    slug,
    user_status: props.github.user_status,
  });
};

// export default HomeContainer;

const mapStateToProps = state => {
  return {
    github: state.authentication.github.data,
  };
};

export default connect(mapStateToProps)(HomeContainer);
